import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/Kingdom-School.png'
import Cta from '../images/new/banners/Kingdom-School-2.png'
import Ellipse from '../images/new/icons/ellipse.svg'

import { 
  section,
  HeaderBanner,
  container,
  content
} from '../components/styles/Base.styles'

const KingdomKids = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="KINGDOM SCHOOL | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]}>
      <div css={container}>
        <h1>Kingdom School</h1>
        <p>Um projeto inteligente, bonito, funcional e alegre. A Kingdom School é um daqueles espaços que você entra e quer ficar.</p>
        <p>As salas são dinâmicas, claras, arejadas e os espaços se conectam muito fácil. Na circulação da escola há muitas novidades, seja de forma expositiva (pelos projetos que os próprios alunos constroem) ou interativa (através das ilhas de sabor que nosso projeto alimentação proporciona, permitindo que os alunos degustem de frutas e petiscos na troca de uma aula e outra), ou até mesmo aproveitando os lounges espalhados pela escola. Aqui tudo é pensado para o aluno.</p>
        <p>Entre as salas ambiente e demais espaços temos Maker Space, espaços destinados às ciências sociais e as ciências naturais, sala de línguas, matemática, português, biblioteca, música, dança, artes, luta, gastronomia, auditório, quadra poliesportiva, jardim, gramado e parque.</p>
        <p>Além da parte estrutural, que conta com mais de 3000m2, a escola tem uma área total de 9000m.</p>
        <h2>Diferenciais que viabilizam nosso projeto</h2>
        <p>Para dar forma e resultados a nossa filosofia de trabalho toda a escola foi idealizada para isso, levando em consideração os seguintes pontos:</p>
        <ul>
          <li>
            <img src={Ellipse} alt='' style={{ width: 'auto' }} />
            <p>Salas com número de alunos reduzido, para termos um alinhamento bem personalizado professor-aluno.</p>
          </li>
          <li>
            <img src={Ellipse} alt='' style={{ width: 'auto' }} />
            <p>Professores constantemente atualizados que tem como conceito que rege suas ações – Learnability - a capacidade de aprendizado, de se manter conectado com o conhecimento e de descobrir novas formas de conhecimento e de transferir.</p>
          </li>
          <li>
            <img src={Ellipse} alt='' style={{ width: 'auto' }} />
            <p>Salas de aula ambiente. Apesar da turma formar uma equipe, o grupo não permanece na mesma sala. Os alunos não têm uma sala de aula fixa, eles transitam com autonomia e segurança entre as salas de acordo com a disciplina que irão trabalhar a cada momento.</p>
          </li>
        </ul>
        <img src={Cta} alt='' style={{ width: '100%' }} />
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default KingdomKids
